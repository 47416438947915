@import '../../styles/variables';

.fo-rules-page {
  &__container {
    display: grid;
    grid-template-rows: auto 1fr;
  }

  &__selection-panel {
    display: flex;
    align-items: flex-end;
    font-size: 14px;
    flex-wrap: wrap;
    padding-left: 28px;
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

