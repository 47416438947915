.delivery-window-ui {

  &__rules {
    &__page-layout {
      grid-template-rows: auto auto 1fr;
    }

    &__container {
      display: grid;
      grid-template-rows: auto 1fr;
      padding: 46px 20px 0;
    }
  }
}
.optionListContainer {
  width: 120px;
  right: 0;
}
