@import './functions.scss';

$black: #000;
$white: #fff;
$gray: #dddddd;

$modal-content-height: 292px;
$black-transparent: colorWithOpacity($black, 0.5);
$light-black: colorWithOpacity($black, 0.05);
$dark-gray: #9B9B9B;
$dark-gray-2: #808080;
$dark-gray-3: #d5d5d5;
$violet: #7253DE;
$dark-violet: #4a3496;
$red: #DB4D41;
$dark-red: #D0021A;
$error-red: #ca5647;
$light-red: colorWithOpacity($red, .04);
$green: green;
$yellow: #FFD102;
$orange: #FF8140;
$blue: #67B8DD;
$dark-blue: #4A90E2;
$opacity-blue: colorWithOpacity($dark-blue, .1);
$dark-yellow: #FDCF00;
$brown-yellow: #66560f;
$dirty-yellow: colorWithOpacity($yellow, .3);
$brown-transparent: rgba(255,209,0,0.05);
$light-green: #519A00;
$dark-green: #80BA40;
$light-green-2: #7ED321;
$gray: #e2e2e2;
$light-gray: #f2f2f2;
$light-gray-2: #ececec;
$dark-gray-secondary: #797979;
$navigationGradientStart: #7D9FFF;
$navigationGradientEnd: #3EE5A1;
$backgroundGradient: linear-gradient(135deg, #A80C8F 0%, #B5990F 100%);
$disabled-dropdown: colorWithOpacity($white, .9);

$link-hover: colorWithOpacity($white, 0.2);
$disabled-input-color: $light-black;

$grey-half-opacity: colorWithOpacity($gray, 0.5);
$grey-small-opacity: colorWithOpacity($gray, 0.05);
$light-gray-opacity: colorWithOpacity($light-gray-2, 0.2);

$border-gray: $light-gray-2;
$font-blue: #4A90E2;
$transition-color: propWithTransition(color, .15s);
$transition-background: propWithTransition(background-color, .15s);

// table colors
$row-dark: colorWithOpacity($gray, .2);
$row-yellow: colorWithOpacity($yellow, .3);
$row-light: $grey-small-opacity;
$row-hover: colorWithOpacity($blue, .1);
$row-selected: colorWithOpacity($blue, .2);
$table-header-color: $white;
$header-row: colorWithOpacity($blue, .3);

$active-row-list-color: colorWithOpacity($dark-green, 0.2), white 45%;


// modal colors
$override-label: #6846DC;

//dark-theme
$dark-theme-bg: #161616;
$link-hover-dark: colorWithOpacity($black, 0.4);

