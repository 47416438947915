.navigation-banner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transition: .5s;

  &--collapsed {
    height: 0;
  }
}
