@import '../../styles/variables';
@import '../../styles/fonts';

.fo-popup {
  padding: 20px;

  &__label {
    color: $black;
    font-family: $primary-font;
    font-size: 24px;
    letter-spacing: -0.55px;
    line-height: 29px;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin: 30px 0 0;
  }

  &__apply {
    margin-left: 20px;
  }

  &__apply,
  &__cancel {
    padding-top: 0;
    padding-bottom: 0;
  }
}
