@import '../../styles/variables';

.fo-text-field {
  position: relative;
  height: 100%;

  &__loupe {
    position: absolute;
    width: 20px;
    height: 20px;
    top: calc(50% - 10px);
    left: 10px;
  }

  input {
    height: 100%;
    width: 175px;
    border: 1px solid $gray;
    background-color: $white;
    border-radius: 0;
    padding: 5px 6px;
    outline: none;

    &:focus {
      border-color: $black;
      color: $black;
    }

    &:disabled {
      background-color: $disabled-input-color;
      color: $black-transparent;
    }
  }

  &__search {
    input {
      padding-left: 35px;
      width: 100%;
    }
  }

  &--valid {
    input {
      border: 1px solid $green;
      color: $black;
    }
  }

  &--invalid {
    input {
      border: 1px solid $red;
      color: $red;
    }
  }
}

