@import '../../styles/variables';
@import '../../styles/fonts.scss';

.fo-dropdown {
  border: 1px solid $border-gray;
  min-width: 190px;
  font-size: 14px;
  display: inline-block;
  position: relative;
  outline: none;
  color: $black;
  background: $white;

  &--searchable {
    min-width: 240px;
  }

  &--fluid {
    display: flex;
    flex-direction: column;
  }

  &__search {
    height: 38px;
    color: $gray;
    border-top: 1px solid $border-gray;
    border-bottom: 1px solid $border-gray;
    margin-top: -1px;

    .fo-text-field input {
      border: none;
      width: 100%;
    }
  }

  &__selected-field {
    padding: 2px 26px 2px 5px;
    user-select: none;
    cursor: pointer;
    min-height: 28px;
  }

  &--disabled {
    pointer-events: none;
    background: $disabled-dropdown;
    color: $black-transparent;
  }

  &__selected-option {
    background: $light-black;
  }

  &__wrapper {
    position: absolute;
    z-index: 10;
    background: white;
    left: -1px;
    right: -1px;
    box-shadow: 0 25px 20px 0 $light-black;
    border: 1px solid $border-gray;
    box-sizing: border-box;

    .fo-dropdown--fluid & {
      position: relative;
      left: 0;
      border: none;
    }
  }

  &__label {
    padding: 8px 10px;
  }

  &__options {
    padding: 6px 0;
    max-height: 155px;
    overflow-y: auto;

    .fo-dropdown--fluid & {
      flex: 1;
    }
  }

  &__option {
    padding: 8px 9px;
    width: 180px !important;
    cursor: pointer;
    position: relative;
    min-width: max-content;
    width: 100%;

    &:hover {
      background: colorWithOpacity($light-black, 0.02);
    }
  }

  &__arrow {
    position: absolute;
    fill: $black;
    width: 12px;
    height: 12px;
    top: 8px;
    right: 8px;
  }

  &__bulk-actions {
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin: 10px;
    font-size: 14px;
    flex-wrap: nowrap;

    &-label {
      color: $dark-gray;
      font-family: $primary-font;
      text-transform: uppercase;
      margin-right: 20px;
    }

    &-row {
      font-family: $secondary-font;
      color: $font-blue;
      display: flex;
      flex-wrap: nowrap;
    }

    &-item {
      cursor: pointer;
      user-select: none;
      white-space: nowrap;

      & + & {
        border-left: 1px solid $gray;
        padding-left: 10px;
        margin-left: 10px;
      }
    }
  }
}

