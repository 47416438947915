@import '../../styles/variables.scss';
@import '../../styles/fonts.scss';

@mixin button-reset {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}

.fo-button {
  @include button-reset();

  outline: none;
  box-sizing: border-box;
  transition: .2s;
  border-radius: 1px;

  &--secondary {
    text-transform: uppercase;
    border: 1px solid $dark-gray;
    color: $dark-gray;
    font-family: $primary-font;
    padding: 4px 22px;

    &:hover {
      border-color: $black;
      color: $black;
    }
  }

  &--primary {
    text-transform: uppercase;
    border: 1px solid $black;
    color: $black;
    font-family: $primary-font;
    padding: 4px 46px;

    &:hover {
      transition: 0.2s;
      color: $white;
      background: $black;
    }
  }

  &--tab-secondary {
    border: 1px solid $dark-gray;
    color: $black;
    padding: 6px 22px;

    &:hover {
      background: $dark-gray;
      color: $white;
    }
  }

  &--tab-primary {
    background: $violet;
    color: $white;
    padding: 6px 22px;

    &:hover {
      background: $dark-violet;
    }
  }

  &--small {
    min-width: 100px;
    padding: 3px 0;
  }

  &:disabled {
    background: colorWithOpacity($black, .05);
    color: $dark-gray;
    border-color: $light-gray;
    cursor: not-allowed;
    user-select: none;

    &:hover {
      border-color: $light-gray;
    }
  }
}

