@import '../../styles/variables.scss';
@import '../../styles/fonts.scss';

.header {
  display: grid;
  grid-template-columns: 5px auto 1fr auto auto 80px auto 5px;
  grid-column-gap: 15px;
  align-items: center;
  background: $black;
  min-height: 60px;
  text-transform: uppercase;

  &__heading {
    font-family: $primary-font;
    margin: 0;
    font-weight: normal;
    font-size: 20px;
    text-align: left;
    letter-spacing: 0;
  }

  &__headingButton {
    font-family: $primary-font;
    margin: 0;
    font-weight: normal;
    font-size: 20px;
    text-align: left;
    letter-spacing: 0;
    background-color: black;
    color: white;
  }

  &__logo {
    grid-column: 2 / 3;
    cursor: pointer;
  }

  &__user {
    border-radius: 100px;
    width: 40px;
    height: 40px;
    background: lightgreen;
    justify-self: center;
    text-align: center;
  }

  &__user-avatar {
    line-height: 40px;
    font-size: 20px;
    font-weight: bold;
  }
}

.header-logo {
  width: 64px;
  height: 32px;
  vertical-align: middle;
  fill: $white;
}
