@import '../../styles/variables';
@import '../../styles/fonts';

.not-found {
  font-family: $alternative-font;
  display: flex;
  padding-top: 24vh;
  align-items: center;
  flex-direction: column;
  color: $black;
  background: $white;
  text-transform: uppercase;
  flex: 1;

  &--dark-theme {
    background: $dark-theme-bg;
    color: $white;
  }

  &__nike-logo {
    width: 56px;
    height: 76px;
    margin-bottom: 76px;

    &--dark-theme {
      fill: $white;
    }
  }

  &__info {
    margin-left: -50px;

    &__heading {
      font-size: 56px;
      text-align: left;
      line-height: 56px;
      letter-spacing: -1.27px;
    }

    &__subheading {
      font-size: 24px;
      line-height: 24px;
      font-family: $primary-font;
      color: $gray;
      text-align: left;
    }
  }

  &__go-back {
    margin-top: 94px;
    padding: 10px 48px;
    border: 1px solid $black;
    font-size: 18px;
    background: transparent;

    &:hover {
      transition: 0.2s;
      color: $white;
      background: $black;
    }

    &--dark-theme {
      border-color: $white;
      color: $white;

      &:hover {
        color: $black;
        background: $white;
      }
    }
  }
}
