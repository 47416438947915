@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/functions';

.page-layout {
  display: grid;
  height: 100vh;
  grid-template-columns: auto;
  grid-template-rows: auto 1fr;

  &__container {
    overflow-y: auto;
    color: $black;
    font-family: $secondary-font;
    transition: $transition-background;
    display: flex;
    padding: 0;

    &--dark-theme {
      background: $dark-theme-bg;
      color: $white;
      transition: $transition-background;
    }
  }

  .centralize-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer{
    display: flex;
    grid-template-columns: 5px auto 1fr auto auto 80px auto 5px;
    grid-column-gap: 15px;
    align-items: center;
    background: $black;
    min-height: 60px;
    text-transform: uppercase;
    position: absolute;
    bottom:0;
    width: 100%;
  }

  .footer-text{
    color: #999;
    font-size: 10px;
    padding: 0 0 0 0;
    line-height: 14px;
    margin: 5px 0;
    text-transform: uppercase;
    float: right;
    right: 5%;
    position: absolute;
  }
}
