@import '../../styles/variables';

.custom-btn {

  color: $black;
  cursor: pointer;
  transition: .3s;
  width: 130px;
  text-align: center;
  user-select: none;
  margin-left: inherit;
  font-family: 'Nike TG';
  font-size: initial;

  &:hover {
    color: $font-blue;
  }

  &--disabled {
    color: grey;
    cursor: not-allowed;
    &:hover {
      color: grey;
    }
  }

  
  &__custom-icon {
    fill: $black;
    margin-left: auto;
    width: 12px;
    height: 12px;
    margin: 0 6px;

    &--disabled {
      fill: grey;
    }
  }
}
