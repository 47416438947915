@import '../../styles/variables';

.fo-control-panel {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  &__counter {
    min-width: 150px;
    font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  &__spinner {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    text-indent: 10px;
    font-size: 14px;
    font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  &__open-button {
    font-size: 14px;
    border-width: 1px;
    margin-right: 12px;

    &.fo-button {
      padding: 0 14px;
    }

    &--dark-theme.fo-button {
      border: 1px solid $white;
      color: $white;

      &:hover {
        background: $white;
        color: $black;
      }
    }

    &--disabled {
      &:hover {
        background: colorWithOpacity($black, .05);
        color: $dark-gray;
        border-color: $dark-gray;
      }
    }
  }

  &__controller {
    user-select: none;
    transition: .3s;
    width: 84px;
    text-align: center;
  }

  &__divider-vertical {
    height: 16px;
    width: 1px;
    margin: 0 8px;
    background: $gray;
  }

  &__divider-horizontal {
    width: 20px;
    height: 1px;
    margin: 0 26px;
    background: $gray;
  }

  &--no-events {
    pointer-events: none;
  }

  &__filters-control {
    display: flex;
    align-items: center;
  }

  &__clear-all {
    margin-left: 10px;
  }

  &__filter-icon {
    width: 20px;
    height: 20px;
    margin: 0 12px;
  }

  &__btn {
    &--disabled {
      color: $dark-gray;
    }
  }

  &__upload-btn {
    cursor: pointer;
    margin-left: auto;
    width: 10px;
    font-family: 'Nike TG';
    font-size: initial;
  }
}
