@import '../../styles/variables.scss';
@import '../../styles/fonts.scss';

.fo-table {
  margin-top: 25px;
  position: relative;
  height: 550px;

  &__no-data-msg {
    position: absolute;
    top: 100px;
    left: 50%;
    z-index: 99;
    transform: translate(-50%, 0);
    color: $dark-gray;
    font-size: 14px;
    letter-spacing: 0.02px;
    line-height: 16px;
    font-family: $primary-font;
    text-transform: uppercase;
  }
  // & > div > div {
  //   overflow-x: hidden !important;
  // }
}
