@import '../../styles/fonts.scss';
@import '../../styles/variables.scss';

.fo-notification {
  display: flex;
  flex-direction: column;
  width: 354px;
  min-height: 70px;
  font-family: $secondary-font;
  position: relative;
  color: $black;
  border-radius: 4px;
  overflow: hidden;
  background: $white;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.16);
  padding: 10px 0 10px 16px;
  margin-left: auto;
  justify-content: flex-start;

  &__head {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    width: 100%;
    align-items: center;
    margin-bottom: 6px;
  }

  &__message {
    line-height: 18px;
    font-size: 14px;
  }

  &__heading {
    line-height: 22px;
  }

  &__closer {
    min-width: 20px;
    max-width: 20px;
    height: 20px;
    margin-right: 10px;
    cursor: pointer;
    align-self: flex-start;
    fill: $dark-gray;
  }

  &__general-info {
    display: flex;
    align-items: center;
  }

  &::before {
    content: '';
    display: block;
    height: 100%;
    width: 6px;
    position: absolute;
    left: 0;
    top: 0;
  }

  &__icon {
    fill: $white;
    padding: 3px;
    max-width: 20px;
    min-width: 20px;
    height: 20px;
    background: $light-green;
    border-radius: 100px;
    margin-right: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--success {

    &::before {
      background: $light-green;
    }

    &-general-info {
      color: $light-green;
    }

    &-icon {
      background: $light-green;
    }
  }

  &--error {

    &::before {
      background: $dark-red;
    }

    &-general-info {
      color: $dark-red;
    }

    &-icon {
      background: $dark-red;
    }
  }

  &--warn {

    &::before {
      background: $dark-yellow;
    }

    &-general-info {
      color: $dark-yellow;
    }

    &-icon {
      background: $dark-yellow;
    }
  }
}
