@import '../../styles/variables.scss';
@import '../../styles/fonts.scss';

.fo-checkbox {
  font-family: $secondary-font;
  color: $dark-gray;
  font-size: 14px;
  line-height: 17px;
  position: relative;

  &__input {
    width: 100%;
    height: 100%;
    margin: 0;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &__label {
    display: flex;
    align-items: center;

    color: $black;
    position: relative;
  }

  &__label::before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: sub;
    width: 15px;
    height: 15px;
    border: 1px solid $black;
    background: $white;
  }

  &__input:checked + &__label {
    color: $black;
    text-shadow: .5px 0 0 black;
  }

  &__input:checked + &__label::after {
    content: '';
    position: absolute;
    left: 5px;
  //  top: 4px;
    width: 5px;
    height: 8px;
    border: solid $black;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
