@import "../../styles/variables";
@import "../../styles/fonts";

.fo-delete-modal {
  padding: 20px;

  &__message {
    color: $dark-gray;
    font-family: $secondary-font;
    font-size: 14px;
    letter-spacing: 0.02px;
    line-height: 17px;
    margin: 15px 0;
    max-width: 330px;
  }

  &__count {
    font-weight: bold;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin: 30px 0 0;
  }

  &__apply {
    margin-left: 20px;
  }

  &__apply,
  &__cancel {
    padding-top: 0;
    padding-bottom: 0;
  }

  &__block {
    margin-top: 12px;
  }

  &__label {
    color: $dark-gray;
    font-family: $primary-font;
    font-size: 14px;
    letter-spacing: 0.02px;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 10px;
    user-select: none;
  }
}
