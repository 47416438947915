@import '../../styles/variables';
@import '../../styles/fonts.scss';
@import '../../styles/keyframes.scss';

.file-uploader-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 0;
  max-width: 520px;
  min-width: 300px;

  .fo-file-upload-confirmation-toast {
    color: $black;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    margin: 30px 0 10px;
  }

  .display-box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $white;
    height: 300px;
    width: 100%;
    padding: 10px;
    color: $dark-gray-secondary;

    &.disabled {
      pointer-events: none;
    }

    &:hover,
    &.drag-over {
      .icon-text-box {
        background-color: $dark-gray-3;
        cursor: pointer;
        color: $black;
        text-shadow: 0px 1px 0 $light-gray;

        .upload-icon {
          transform: translateY(-20px);
          animation: bounce 1s ease-in-out infinite;

          &--svg {
            fill: $black;
          }
        }
      }
    }

    .icon-text-box {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-width: 1px;
      border-style: dashed;
      border-color: $dark-gray;
      width: 100%;
      height: 100%;
      text-shadow: 0px 0px 0 #999;
      transition: background-color 0.2s ease-in, color 0.2s ease-in, border-color 0.2s ease-in, text-shadow 0.2s ease-in;

      .upload-icon {
        margin: 30px;
        box-sizing: border-box;
        transform: translateY(0px);
        transition: transform 0.2s ease-in-out;

        &--svg {
          width: 50px;
          height: 50px;
          fill: $dark-gray-secondary;
        }

      }
      .upload-button,
      .cancel-upload-button {
        margin: 0 10px;
        position: relative;
        z-index: 9999;
      }

      .error-notification {
        animation: fadeInDown 0.5s;
        position: absolute;
        top: 20px;
        background-color: $dirty-yellow;
        color: $black;
        padding: 5px 10px;
        border: 1px solid $yellow;

        p {
          margin: 0;
          text-shadow: none;
        }
      }
    }
    .upload-file-input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }

  @media (min-width: 768px) {
    .display-box {
      width: 500px;
    }
  }
}
