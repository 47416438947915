$gradient1StartColor: #7D9FFF;
$gradient1EndColor: #3EE5A1;
$gradient2StartColor: #3617EB;
$gradient2EndColor: #FF4F4F;
$gradient3StartColor: #FFC585;
$gradient3EndColor: #FF4F4F;

.fo-waves {
  height: 100%;
  width: 100%;
  position: absolute;

  &__gradient {
    &--first {
      &.start-color {
        stop-color: $gradient1StartColor;
      }

      &.end-color {
        stop-color: $gradient1EndColor;
      }
    }

    &--second {
      &.start-color {
        stop-color: $gradient2StartColor;
      }

      &.end-color {
        stop-color: $gradient2EndColor;
      }
    }

    &--third {
      &.start-color {
        stop-color: $gradient3StartColor;
      }

      &.end-color {
        stop-color: $gradient3EndColor;
      }
    }
  }
}
