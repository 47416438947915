@import '../../../styles/variables.scss';
@import '../../../styles/keyframes.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/functions';

.editable-table-row {
  display: grid;
  grid-template-columns:
  minmax(100px, 1.0fr) 
  minmax(120px, 1.2fr) 
  minmax(100px, 1.4fr) 
  minmax(120px, 1.2fr) 
  minmax(70px, 0.9fr) 
  minmax(70px, 0.9fr) 
  minmax(70px, 0.9fr) 
  minmax(80px, 1.2fr) 
  minmax(80px, 1.1fr) 
  minmax(100px, 1fr) 
  minmax(80px, 1fr); 
  column-gap: 2px;
  position: relative;
  height: 50px;
  font-size: 12px;
  letter-spacing: 0.02px;
  font-family: $secondary-font;
  min-width: min-content;
  margin-bottom: 5px;

  &--no-data {
    opacity: .4;
  }

  &__checkbox-wrapper {
    position: sticky;
    height: 28px;
    width: 26px;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    background: $white;
    transition: $transition-background;

    &--dark-theme {
      transition: $transition-background;
      background: $dark-theme-bg;
    }

    .fo-checkbox__input:checked + .fo-checkbox__label::after {
      top: 3px;
    }
  }

  &--selected {
    &::before {
      content: '';
      position: absolute;
      top: 1px;
      left: 0;
      height: 26px;
      background-color: $row-selected;
      width: 100%;
    }
  }

  &__hidden {
    visibility: hidden;
  }

  &__value {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &--no-events {
    pointer-events: none;
  }

  &--has-error {
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      animation: pulse 1s;
      animation-fill-mode: forwards;
      width: 100%;
      z-index: 0;
      height: 28px;
      // color comes from keyframe
      border: 1px solid transparent;
    }
  }

  &__item {
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0 8px 22px 5px;
    background-color: $row-light;
    user-select: none;
    position: relative;
    margin: 1px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__edrMin,
    &__edrMax,
    &__activePadding,
    &__price {
      text-align: right;
    }

    &--unchecked {
      opacity: .2;
    }

    &--even {
      background-color: $row-dark;
    }
  }

  &__overrides {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    font-weight: bold;
    color: $white;
    text-align: right;
  }

  &--active-padding {
    font-weight: bold;
    color: $light-green-2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: colorWithOpacity($light-green-2, .2);
    padding-right: 6px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &--is-clickable {
    &:hover {
      background-color: $row-hover;
      cursor: pointer;
    }
  }

  &__tooltip-message {
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__alert-symbol {
    &--dark-theme {
      color: $brown-yellow;
      transition: $transition-color;
    }
  }

  &__info {
    width: 20px;
    height: 20px;
  }

  &__item-wrapper {
    overflow: visible;
  }

  // new styling
  &__input {
    &.fo-text-field.hasError {
      input{
        border-color: red;
      }
      
    }
    input {
      width: 100%;
      font-size: 12px;
      & +span+span {
        display: block;
        color: red;
      }
    }
  }

  &__dropdown {
    font-size: 12px;
    height: 100%;
    width: 120%;

    .fo-dropdown__selected-field {
      padding: 0 26px 0 5px;
    }

    .fo-dropdown__selected-field,
    .fo-dropdown__option {
      text-transform: none;
    }

    .fo-dropdown__options {
      max-height: 200px;
      position: fixed;
      background: white;
      border: 1px solid #ececec;
    }

    .fo-dropdown__arrow {
      fill: $dark-gray;
      width: 10px;
      height: 10px;
    }
  }
}
