@import '../styles/variables.scss';

@keyframes pulse {
  0% {
    background-color: colorWithOpacity($red, 0);
    border-color: colorWithOpacity($red, 0);
  }
  100% {
    background-color: $light-red;
    border-color: $error-red;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes bounce {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    opacity: 0.7;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translate(0px);
  }
}
