@font-face {
  font-family: 'Nike TG';
  font-style: normal;
  font-weight: 400;
  src: url('~fontsAlias/nike-tg.eot'); /* IE9 Compat Modes */
  src: url('~fontsAlias/nike-tg.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('~fontsAlias/nike-tg.woff') format('woff'), /* Modern Browsers */
  url('~fontsAlias/nike-tg.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Nike Futura';
  font-style: normal;
  font-weight: 400;
  src: url(~fontsAlias/nike-futura.eot);
  src: url(~fontsAlias/nike-futura.eot?#iefix) format('embedded-opentype'),
  url(~fontsAlias/nike-futura.woff) format('woff'),
  url(~fontsAlias/nike-futura.ttf) format('truetype');
}
