@import '../../styles/variables';

.fo-file-upload-confirmation-toast,
.fo-confirmation-toast {
  margin-top: 20px;
  padding: 3px 8px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  background: $dirty-yellow;
  border: 1px solid $yellow;

  &__bold {
    font-weight: bold;
  }

  &-actions {
    display: flex;
    align-items: center;
  }

  &__divider {
    width: 10px;
    height: 1px;
    background: $black;
    margin: 0 8px;
  }

  &__action {
    cursor: pointer;
    color: $dark-blue;
  }

  &__file-name {
    color: $black;
    font-weight: bold;
  }
}

.fo-file-upload-confirmation-toast {
  align-items: flex-start;
  justify-content: flex-start;

  &-actions {
    align-self: flex-end;
  }
}
