@import './styles/nikeFonts.scss';
@import './styles/common.scss';
@import './styles/fonts.scss';

body {
  margin: 0;
  color: white;
  font-family: $secondary-font;
  height: 100vh;
  overflow-y: hidden;
  font-weight: 400;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  font-weight: normal;
}
