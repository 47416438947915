@import '../../../styles/variables.scss';
@import '../../../styles/fonts.scss';

.fo-table__header {
  display: grid;
  grid-template-columns:
    24px
    minmax(100px, 1.0fr) 
    minmax(70px, 0.9fr) 
    minmax(100px, 1.4fr) 
    minmax(70px, 0.9fr) 
    minmax(70px, 0.9fr) 
    minmax(70px, 0.9fr) 
    minmax(70px, 0.9fr) 
    minmax(80px, 1.2fr) 
    minmax(80px, 1.1fr) 
    minmax(100px, 1fr) 
    minmax(80px, 1fr);
  column-gap: 2px;
  height: 38px;
  position: sticky;
  top: 0;
  z-index: 100;
  min-width: min-content;
  font-family: $primary-font;
  background: $white;
  transition: $transition-background;

  &__placeholder {
    position: sticky;
    width: 26px;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    height: 100%;
    background: inherit;
  }

  &__checkbox-wrapper {
    display: flex;
    align-items: center;

    position: sticky;
    height: 38px;
    width: 26px;
    top: 0;
    left: 0;
    z-index: 1;
    background: $white;
    transition: $transition-background;
  }

  &--dark-theme {
    background: $dark-theme-bg;
    transition: $transition-background;
  }
}
