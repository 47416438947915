@import '../../styles/variables';

.fo-warning {
  border: 1px solid $border-gray;
  display: flex;
  color: $black;
  font-size: 14px;
  letter-spacing: 0.02px;
  line-height: 17px;
  justify-content: space-around;
  align-items: center;
  padding: 0 10px;
  margin: 20px 0;

  &__icon {
    width: 120px;
    margin: 0 20px 0 5px;
  }

  &__text {
    max-width: 178px;
  }
}
