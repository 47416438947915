@import '../../styles/variables';

.fo-textarea {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  textarea {
    height: 100%;
    width: 100%;
    border: 1px solid $gray;
    background-color: $white;
    border-radius: 0;
    padding: 6px;
    outline: none;
    resize: none;

    &:disabled {
      background-color: $disabled-input-color;
      color: $black-transparent;
    }

    &::placeholder {
      color: $dark-gray;
    }
  }

  &--valid {
    textarea {
      border: 1px solid $green;
      color: $black;
    }
  }

  &--resizable {
    textarea {
      resize: vertical;
    }
  }

  &--invalid {
    textarea {
      border: 1px solid $red;
      color: $red;
    }
  }

  span {

    &:last-of-type {
      color: $dark-gray;
      font-size: 10px;
      align-self: flex-end;
    }
  }
}
