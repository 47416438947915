@function colorWithOpacity($color, $opacity) {
  @return rgba($color, $opacity);
}

@function propWithTransition($prop, $duration) {
  @return $prop $duration ease-out;
}

@mixin gradient($direction, $list) {
  background: -webkit-linear-gradient($direction, $list);
  background: -moz-linear-gradient($direction, $list);
  background: -o-linear-gradient($direction, $list);
  background: linear-gradient($direction, $list);
}
