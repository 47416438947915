@import "../../styles/variables";
@import "../../styles/fonts";

.add-edit-modal {
  color: $black;

  .delivery-window-table {
    grid-template-columns:
    minmax(100px, 1.0fr) 
    minmax(120px, 1.2fr) 
    minmax(100px, 1.4fr) 
    minmax(120px, 1.2fr) 
    minmax(70px, 0.9fr) 
    minmax(70px, 0.9fr) 
    minmax(70px, 0.9fr) 
    minmax(80px, 1.2fr) 
    minmax(80px, 1.1fr) 
    minmax(100px, 1fr) 
    minmax(80px, 1fr); 
  }

  &__table {
    margin: 30px 0 50px;
    display: flex;
    flex-direction: column;
    overflow-x: auto;

    &__header-row {
      background-color: $opacity-blue;
      grid-template-columns:
      minmax(100px, 1.0fr) 
      minmax(120px, 1.2fr) 
      minmax(100px, 1.4fr) 
      minmax(120px, 1.2fr) 
      minmax(70px, 0.9fr) 
      minmax(70px, 0.9fr) 
      minmax(70px, 0.9fr) 
      minmax(80px, 1.2fr) 
      minmax(80px, 1.1fr) 
      minmax(100px, 1fr) 
      minmax(80px, 1fr); 

      margin-bottom: 8px;
    }
  }

  &__scrollable {
    flex: 1;
    overflow: auto;
    max-height: 160px;
  }

  &__label {
    color: $dark-gray;
    font-family: $primary-font;
    font-size: 14px;
    letter-spacing: 0.02px;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 10px;
    user-select: none;
  }

  &__block {
    margin-top: 12px;

    &__text-area {
      margin: 20px 0 15px;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin: 30px 0 0;
  }

  &__apply {
    margin-left: 20px;
  }

  &__apply,
  &__cancel {
    padding-top: 0;
    padding-bottom: 0;
  }

}
