@import '../../styles/variables.scss';
@import '../../styles/fonts.scss';
@import '../../styles/functions';

.navigation {
  position: relative;
  font-family: $primary-font;
  background-image: $backgroundGradient;
  height: 98px;
  transition: .5s;

  &__collapsed {
    height: 43px;
  }

  &--short-view {
    height: 46px;
  }

  &__link {
    margin-right: 20px;
    font-size: 16px;
    padding: 5px 10px;
    color: $white;
    user-select: none;

    &--active {
      background: $black;
      pointer-events: none;
    }

    &:hover {
      background: $link-hover;
    }

    &--dark-theme:hover {
      background: $link-hover-dark;
    }
  }

  &__content-holder {
    position: relative;
    text-transform: uppercase;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: flex-end;
    padding-bottom: 7px;
    padding-left: 100px;
    transition: .5s;
  }

  &__full-page {
    height: 100vh;
  }

  .container {
    margin: 0;
    z-index: 1;
  }
}



