@import '../../../styles/variables.scss';
@import '../../../styles/fonts.scss';

.fo-sort-field {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: .2s;
  background: $white;
  padding-left: 0;


  &--with-background {
    background-color: $opacity-blue;
    padding-left: 5px;
  }

  &__label {
    font-size: 12px;
    letter-spacing: 0.02px;
    line-height: 16px;
    font-family: $primary-font;
    margin-left: 6px;
    text-transform: uppercase;
    user-select: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 2px;
  }

  &__labelFilter {
    position: absolute;
    line-height: 16px;
    font-family: $primary-font;
    user-select: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 2px;
    right:0;
    width: 100%;
  }

  
}
.search-wrapper{
  border: 0px !important;
}

.optionContainer{
  font-size: 13px;
}


.selectionMarker{
  font-size: 11px;
  top: 22%;
  position: absolute;
  left: 28%;
  color: #fff;
  border: 1px solid;
  border-radius: 50%;
  width: 12%;
  background-color: #48888a;
  text-align: center;
}
::-webkit-input-placeholder {color:transparent;}

:-moz-placeholder {color:transparent;}

::-moz-placeholder {color:transparent;}

:-ms-input-placeholder {color:transparent;}